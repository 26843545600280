import React from "react";

function Footer() {
  return (
    <footer>
      <h5 className="footer-title">Václav Jirka 2024</h5>
    </footer>
  );
}

export default Footer;
